// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/search-results.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/search-results.tsx");
  import.meta.hot.lastModified = "1699021655121.8594";
}
// REMIX HMR END

import { useEffect, useState } from "react";
export default function SearchResults(props) {
  _s();
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    const fetchResults = async () => {
      const res = await fetch(`//${props.partykitHost}/parties/${props.party}/${props.room}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          query: props.query
        })
      });
      const {
        episodes
      } = await res.json();
      setEpisodes(episodes);
      setLoading(false);
    };
    fetchResults().catch(console.error);
  }, [props.query]);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (!episodes.length) {
    return null;
  }
  return <div className="flex flex-col justify-start items-start gap-1">
      <div className="font-semibold">Episodes</div>
      <ul className="flex flex-col justify-start items-start gap-2">
        {episodes.map(episode => {
        return <li key={episode.id}>
              <a className="text-blue-500 hover:text-blue-700" href={`https://www.braggoscope.com${episode.permalink}`}>
                {episode.title}
              </a>{" "}
              <span className="text-gray-400 text-xs">
                {episode.published}. Score: {episode.score}
              </span>
            </li>;
      })}
      </ul>
    </div>;
}
_s(SearchResults, "4vACSbS8hXB2AKNVq3WcCHPopN0=");
_c = SearchResults;
var _c;
$RefreshReg$(_c, "SearchResults");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;