// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1699094942781.3616";
}
// REMIX HMR END

import { useLoaderData } from "@remix-run/react";
import { SEARCH_SINGLETON_ROOM_ID } from "party/search";
import Search from "~/components/search";

// PartyKit will inject the host into the server bundle
// so let's read it here and expose it to the client
export function loader() {
  return {
    partykitHost: PARTYKIT_HOST
  };
}
export const meta = () => {
  return [{
    title: "Braggoscope search"
  }, {
    name: "description",
    content: "Find episodes of BBC In Our Time"
  }];
};
export default function Index() {
  _s();
  const {
    partykitHost
  } = useLoaderData();
  return <div className="mx-auto flex flex-col justify-start items-start gap-6 w-full max-w-md">
      <h1 className="text-3xl font-semibold">Braggoscope search</h1>
      <Search partykitHost={partykitHost} party="search" room={SEARCH_SINGLETON_ROOM_ID} />
    </div>;
}
_s(Index, "6dU/y19IOBvBWYWmZ6/W1oe5w14=", false, function () {
  return [useLoaderData];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;